import React from 'react'
import styled from 'styled-components'

import Button from 'components/shared/button'
import { Text } from 'components/shared/typography'

type Props = {
  text: string
}

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
`

const StyledButton = styled(Button)`
  align-self: flex-start;
  padding: 15px;
  font-size: 17px;
`

const InfoWithButton: React.FC<Props> = ({ text }) => {
  return (
    <Wrapper>
      <Text
        size={14}
        weight={400}
        themecolor="black"
        dangerouslySetInnerHTML={{ __html: text }}
      />
      <a href="/gdzie-kupic/">
        <StyledButton primary300 uppercase>
          zakup i realizacja
        </StyledButton>
      </a>
    </Wrapper>
  )
}

export default InfoWithButton
