import React from 'react'
import styled from 'styled-components'

import InfoWithButton from 'components/layout/Sections/Pages/Akcesoria/InfoWithButton'
import Container from 'components/shared/container'
import { Text } from 'components/shared/typography'

import LazyImage from 'components/shared/lazyImage'
import { ImageDataLike } from 'gatsby-plugin-image'

import useBreakpoint from 'hooks/useBreakpoint'

type AccessoriesItem = {
  img: ImageDataLike
  name: string
  description: string
}

type Props = {
  accessories: AccessoriesItem[]
  infoText: string
}

const Section = styled.section`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray100};
  padding: 20px 0;

  ${({ theme }) => theme.media.lg.min} {
    padding: 40px 0;
    background-color: ${({ theme }) => theme.colors.white};
  }
`

const StyledContainer = styled(Container)``

const TilesWrapper = styled.div`
  display: grid;
  gap: 10px;

  ${({ theme }) => theme.media.lg.min} {
    grid-template-columns: repeat(2, 1fr);
  }
`

const TileSingleWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.gray300};

  ${({ theme }) => theme.media.lg.min} {
    padding: 20px;
    flex-direction: row;
    min-height: 250px;
  }

  &:first-child {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  &:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  ${({ theme }) => theme.media.lg.min} {
    &:first-child {
      border-top-left-radius: 20px;
      border-top-right-radius: 0;
    }

    &:nth-child(2) {
      border-top-right-radius: 20px;
    }

    &:nth-last-child(2) {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 0;
    }

    &:nth-child(odd) {
      margin-right: 15px;
    }
  }
`

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${({ theme }) => theme.media.lg.min} {
    gap: 0;
    margin-right: 10px;
  }
`

const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-height: 250px;

  // Gatsby image wrapper
  div {
    max-height: 100%;
  }

  ${({ theme }) => theme.media.lg.min} {
    min-width: 200px;
  }
`

const Accessories: React.FC<Props> = ({ accessories, infoText }) => {
  const { lg } = useBreakpoint()

  return (
    <Section>
      <StyledContainer>
        <TilesWrapper>
          {accessories.map(({ img, name, description }) => (
            <TileSingleWrapper key={name}>
              <DescriptionWrapper>
                <Text size={20} transform="uppercase" weight={500}>
                  {name}
                </Text>
                <Text size={lg ? 14 : 15}>{description}</Text>
              </DescriptionWrapper>
              <ImgWrapper>
                <LazyImage src={img} alt={name} objectFit="contain" />
              </ImgWrapper>
            </TileSingleWrapper>
          ))}
          <InfoWithButton text={infoText} />
        </TilesWrapper>
      </StyledContainer>
    </Section>
  )
}

export default Accessories
