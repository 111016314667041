import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Hero from 'components/layout/Sections/Hero'
import Accessories from 'components/layout/Sections/Pages/Akcesoria/Accessories'
import Navigation from 'components/layout/Navigation/index'

const breadcrumbs = {
  mobile: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Akcesoria',
    },
  ],
  desktop: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Akcesoria',
    },
  ],
}

const AkcesoriaPage: React.FC<PageProps> = ({ data }) => {
  const PAGE = data?.wpPage?.akcesoria
  const PAGE_SEO = data?.wpPage?.seo

  const ACCESSORIES_GALLERY = React.useMemo(
    () =>
      PAGE?.accessoriesProducts?.map((product) => ({
        name: product?.accessoriesProductsName,
        description: product?.accessoriesProductsDescription,
        img: product?.accessoriesProductsImg?.localFile.childImageSharp
          ?.gatsbyImageData,
      })),
    []
  )

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Hero
        imgMobile={
          PAGE?.accessoriesImg?.localFile?.childImageSharp?.gatsbyImageData
        }
        imgDesktop={
          PAGE?.accessoriesImg?.localFile?.childImageSharp?.gatsbyImageData
        }
        headingMobile={PAGE?.accessoriesTitle}
        headingDesktop={PAGE?.accessoriesTitle}
        breadcrumbs={breadcrumbs}
      />
      <Accessories
        accessories={ACCESSORIES_GALLERY}
        infoText={PAGE?.accessoriesDescription}
      />
    </Layout>
  )
}

export default AkcesoriaPage

export const query = graphql`
  query AkcesoriaPage {
    wpPage(slug: { regex: "/akcesoria/" }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
      }
      id
      akcesoria {
        accessoriesDescription
        accessoriesTitle
        fieldGroupName
        accessoriesProducts {
          accessoriesProductsName
          accessoriesProductsDescription
          accessoriesProductsImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 500
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        accessoriesImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`
